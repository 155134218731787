// app/routes/login.tsx
import { Turnstile } from "@marsidev/react-turnstile";
import {
  type ActionFunctionArgs,
  json,
  type LoaderFunctionArgs,
} from "@remix-run/node";
import { Form, type MetaFunction, useLoaderData } from "@remix-run/react";
import { Button2 } from "~/components/ui/button2";
import { Input } from "~/components/ui/input";
import { Label } from "~/components/ui/label";
import { authenticator } from "~/modules/auth/auth.server";
import { commitSession, getSession } from "~/modules/auth/session.server";
import { useIsPending } from "~/utils";

export const meta: MetaFunction = () => {
  return [{ title: "Login - Acesse sua conta | Bevits" }];
};

export async function loader({ request }: LoaderFunctionArgs) {
  await authenticator.isAuthenticated(request, {
    successRedirect: "/home",
  });

  const session = await getSession(request.headers.get("Cookie"));
  const authError = session.get(authenticator.sessionErrorKey);

  // Commit session to clear any `flash` error message.
  return json(
    { authError },
    {
      headers: {
        "set-cookie": await commitSession(session),
      },
    }
  );
}

export async function action({ request }: ActionFunctionArgs) {
  const clonedRequest = request.clone();
  const formData = await clonedRequest.formData();
  const turnstileResponse = formData.get("cf-turnstile-response");

  // Verify Turnstile response
  const turnstileOutcome = await verifyTurnstileResponse(
    turnstileResponse as string
  );

  if (!turnstileOutcome.success && process.env.NODE_ENV === "production") {
    // If Turnstile verification fails, return an error
    return json({ error: "Turnstile verification failed" }, { status: 400 });
  }

  // If Turnstile verification succeeds, proceed with authentication
  // Use the original request here, not the cloned one
  return authenticator.authenticate("TOTP", request, {
    successRedirect: "/verify",
    failureRedirect: "/login",
  });
}

async function verifyTurnstileResponse(token: string) {
  const response = await fetch(
    "https://challenges.cloudflare.com/turnstile/v0/siteverify",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        secret: process.env.CLOUDFLARE_TURNSTILE_SECRET_KEY,
        response: token,
      }),
    }
  );

  const outcome = await response.json();

  console.log("outcome", outcome);
  return outcome;
}

export default function Login() {
  const { authError } = useLoaderData<typeof loader>();
  const isPending = useIsPending();

  return (
    <div className="flex min-h-screen flex-1">
      <div className="w-full sm:w-1/2">
        <div className="flex h-full w-full flex-col justify-center px-4 py-16 sm:px-32">
          <h1 className="text-3xl font-bold">Entre na sua conta</h1>
          <Form className="mt-8 flex w-full flex-col" method="post">
            <div className="flex flex-col">
              <div className="flex flex-col gap-y-4">
                <div className="flex flex-col gap-y-2">
                  <Label htmlFor="email" className="text-left">
                    Email
                  </Label>
                  <Input
                    id="email"
                    name="email"
                    placeholder="seuEmail@email.com"
                    className="col-span-3"
                  />
                </div>
              </div>
              <div className="mt-4">
                <Turnstile
                  siteKey={"0x4AAAAAAAkTquJ0KKTyiTbU"}
                  options={{
                    action: "submit-form",
                    theme: "light",
                    size: "normal",
                    language: "pt-BR",
                  }}
                />
              </div>
              <div className="flex flex-col gap-y-4">
                <Button2
                  status={isPending ? "pending" : "idle"}
                  disabled={isPending}
                  className="mt-8 w-[200px] h-[42px]"
                  type="submit"
                >
                  Entrar
                </Button2>
              </div>
            </div>
          </Form>
          <Form action="/auth/google" method="post" className="mt-4">
            <button className="border flex items-center h-[40px] rounded-lg px-4 py-2 text-sm text-neutral-700 gap-x-3 w-[200px]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                preserveAspectRatio="xMidYMid"
                viewBox="-3 0 262 262"
              >
                <g>
                  <path
                    fill="#4285F4"
                    d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027"
                  ></path>
                  <path
                    fill="#34A853"
                    d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1"
                  ></path>
                  <path
                    fill="#FBBC05"
                    d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782"
                  ></path>
                  <path
                    fill="#EB4335"
                    d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251"
                  ></path>
                </g>
              </svg>
              Entrar com Google
            </button>
          </Form>
          <span className="text-red-500 mt-4 text-sm">
            {authError?.message}
          </span>
        </div>
      </div>
      <div className="hidden bg-zinc-100 sm:flex sm:w-1/2"></div>
    </div>
  );
}
